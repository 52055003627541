<template>
	<div class="NewAftermarket" v-if="OrderDetail != null">
			
		<div class="OrderInfo">
			<ul class="Left">
				<img :src="OrderDetail.GoodsThumb" />
			</ul>
			<ul class="Right">
				<h3>{{OrderDetail.GoodsName}}</h3>
				<li>单价:<span>{{OrderDetail.Price}}</span>购买数量:<span>{{OrderDetail.Num}}</span>费用小计:<span>{{OrderDetail.TotalFee}}</span>
				<template v-if="OrderDetail.SkuName.length > 0">
					规格:<span>{{OrderDetail.SkuName}}</span>
				</template>
				</li>
			</ul>
		</div>
		
		<!--未发起过申诉-->
		<div class="Form" v-if="Appeal.Id == ''">
			
			<div class="Item">
				<ul class="Left">
					*申诉类型:
				</ul>
				<ul class="Right">
					<li>
						<el-select v-model="Appeal.AppealType">
							<el-option :value="'repair'" :label="'维修'"></el-option>
							<el-option :value="'change'" :label="'换货'"></el-option>
							<el-option :value="'refund'" :label="'退款'"></el-option>
						</el-select>
					</li>
				</ul>
			</div>
			
			<div class="Item">
				<ul class="Left">
					*售后数量:
				</ul>
				<ul class="Right">
					<li>
						<span><el-input-number style="width: 200px;" v-model="Appeal.AppealNum" :max="OrderDetail.Num" :min="1"></el-input-number></span> <span>最多可选择{{OrderDetail.Num}}件</span>
					</li>
				</ul>
			</div>
			
			<div class="Item">
				<ul class="Left">
					*申诉原因:
				</ul>
				<ul class="Right">
					<li>
						<el-select v-model="Appeal.AppealReason">

							<el-option :value="'unreceived'" :label="'货物未收到'"></el-option>
							<el-option :value="'wronggoods'" :label="'货物与描述不符'"></el-option>
							<el-option :value="'badgoods'" :label="'货物质量问题'"></el-option>
							<el-option :value="'wrongnum'" :label="'货物数量不对'"></el-option>
							<el-option :value="'personal'" :label="'买家个人原因'"></el-option>

						</el-select>
					</li>
				</ul>
			</div>
			
			<div class="Item" v-if="Appeal.AppealType == 'refund'">
				<ul class="Left">
					退款金额:
				</ul>
				<ul class="Right">
					<li>
						<span>
							<el-input style="width: 120px;" v-model="Appeal.AppealRefund"></el-input>
						</span>
						<span>单位元,如商品有质量问题,您可视情况与商家协商是否退货</span>
					</li>
				</ul>
			</div>
			
			<div class="Item">
				<ul class="Left">
					补充描述:
				</ul>
				<ul class="Right">
					<li style="max-width: 500px;">
						<el-input auto-complete="off"  v-model="Appeal.AppealDetail" type="textarea"></el-input>
					</li>
				</ul>
			</div>
			
			<div class="Item">
				<ul class="Left">
					图片凭据:
				</ul>
				<ul class="Right">
					<li>
						
						<div class="PreviewImg" v-for="(item,itemI) in Appeal.AppealImages" :key="itemI">
							<div class="ShowContent">
								<img :src="item" class="avatar">
							</div>
							<i class="Remove" @click="removePicWall(itemI)">移除</i>
						</div>
						
						<el-upload v-if="Appeal.AppealImages.length < 5"
						  action="https://jsonplaceholder.typicode.com/posts/"
						  accept="image/jpeg,image/jpg,image/gif,image/png"
						  :show-file-list="false" :on-change="getFilesChange" :limit="5" :auto-upload="false">
							<div class="PreviewImg">
								<div class="ShowContent">
									<i  class="el-icon-plus"></i>
								</div>
							</div>
						</el-upload>
					
						
					</li>
				</ul>
			</div>
			
			<div class="Item">
				<ul class="Left">
				</ul>
				<ul class="Right">
					<li>
						<el-checkbox checked></el-checkbox>
						 我已阅读并同意
						 <em @click="GetAppealArgument()" style="cursor: pointer;">《退换货协议》</em>
						
					</li>
				</ul>
			</div>
			
			<div class="Item">
				<ul class="Left">
				</ul>
				<ul class="Right">
					<li>
						
						<el-button type="primary" @click="UpdateAppeal()">提交售后</el-button>
						
					</li>
				</ul>
			</div>
			
		</div>

	<el-dialog
	  title="提示"
	  :visible="ArgumentDialog"
	  width="800px"
	  :before-close="handleClose">
	  <div>
		  <h3>退换货协议</h3>
		  <ul v-html="ArgumentContent"></ul>
	  </div>
	</el-dialog>
	
	</div>
</template>

<script>
	import {Select,Option,Dialog,Pagination,Upload,Checkbox,InputNumber} from 'element-ui'
	export default {
	  name: 'NewAftermarket',
	  props: {
	  },
	  data() {
	      return {
			  OrderDetail:null,
			  Appeal:{
				Id:'',
				OrderId:'',
				OrderDetailId:'',
				ShopId:'',
				ExpressCompanyName:'',
				ExpressCompanyCode:'',
				ExpressCode:'',
				AppealType:'',//申请类型  repair维修 change换货 refund 退款
				AppealReason:'', //申诉原因类型 货物未收到unreceived 货物发错 wronggoods 货物质量差badgoods 货物数量不对wrongnum 买家个人原因personal
				AppealDetail:'', //申诉描述详情
				AppealImages:[], //申诉图片列表
				AppealRefund:0.00, //申请退款金额
				AppealNum:1, //售后商品数量
				CheckRefund:0.00, //确认的退款金额 可能来自商家/系统客服
				CheckNum:0, //确认的商品数量 可能来自商家/系统客服
				RefuseReason:'', //拒绝理由
				RefuseImages:[],//拒绝图片列表
			  },
			  ArgumentDialog:false,//退货换协议弹框。
			  ArgumentContent:'',
	      }
	  },
	  components: {
		'el-dialog':Dialog,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination,
		'el-upload':Upload,
		'el-checkbox':Checkbox,
		"el-input-number":InputNumber
	  },
	  created() {
	  	this.GetOrderDetail()
	  },
	  methods:{
		  handleClose(){
			  this.ArgumentDialog = false
		  },
		  GetOrderDetail(){ //获取子订单详情
			  let data = {
			  	Service:'Order',
			  	Class: 'Order',
			  	Action: 'SonOrderDetail',
			  	Id:this.$route.params.SonOrderId
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
			  	this.OrderDetail = res.Data
				this.Appeal.OrderId = this.OrderDetail.OrderId
				this.Appeal.OrderDetailId = this.OrderDetail.Id
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  GetAppealArgument(){ //获取退换货协议资讯
		  
			if(this.ArgumentContent != ''){
				this.ArgumentDialog = true
				return
			}
		  
			  let data = {
			  	Service:'News',
			  	Class: 'News',
			  	Action: 'Get',
			  	Id:'c809a1d3-4bb6-477b-b7be-c4a2270c134c'
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  	
			  	this.ArgumentContent = res.Data.Detail
				this.ArgumentDialog = true
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  getFilesChange(file, fileList) {
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		  },
		  getFileChange(file, fileList) {
			this.getBase64(file.raw).then(res => {
				this.uploadPic(res)
			});
		  },
		  getBase64(file) {
			return new Promise(function(resolve, reject) {
			  let reader = new FileReader();
			  let imgResult = "";
			  reader.readAsDataURL(file);
			  reader.onload = function() {
				imgResult = reader.result;
			  };
			  reader.onerror = function(error) {
				reject(error);
			  };
			  reader.onloadend = function() {
				resolve(imgResult);
			  };
			});
		  },
		  uploadPic(_b64){
			  let data = {
				Service:'Goods',
				Class: 'File',
				Action: 'Base64Upload',
				Base64:_b64
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				if(this.Appeal.AppealImages.length < 5){ //如果图墙未满，则将主图添加到图墙
					this.Appeal.AppealImages.push(res.Data.Url)
				}
				
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  },
		  removePicWall(_index){
			  this.Appeal.AppealImages.splice(_index,1)
		  },
		  UpdateAppeal(){ //提交申诉
			  let data = {
				Service:'Order',
				Class: 'Appeal',
				Action: 'Update',
				Id:this.Appeal.Id,
				AppealType:this.Appeal.AppealType,
				AppealNum:this.Appeal.AppealNum,
				AppealReason:this.Appeal.AppealReason,
				AppealRefund:this.Appeal.AppealRefund,
				AppealDetail:this.Appeal.AppealDetail,
				AppealImages:JSON.stringify(this.Appeal.AppealImages),
				OrderId:this.Appeal.OrderId,
				OrderDetailId:this.Appeal.OrderDetailId,
			  }
			  if(this.Appeal.Id == ''){
				  data.Action = "Add"
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				if(res.ErrorId != 0){
					this.$message.error(res.Msg)
					return
				}
				
				this.$message('申诉提交已完成')
				//提取申诉信息
				
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  }
	  }
	}
</script>

<style scoped>
	.NewAftermarket{
		margin: 20px;
		background-color: #FFFFFF;
		padding: 20px;
	}
	.OrderInfo{
		display: flex;
		width: 100%;
		border-bottom: 1px solid rgba(0,0,0,0.1);
	}
	.OrderInfo .Left{
		width: 200px;
		text-align: center;
	}
	.OrderInfo .Left img{
		width: 100px;
		height: 100px;
	}
	.OrderInfo .Right{
		flex: 1;
	}
	.OrderInfo .Right li{
		color: #999999;
	}
	.OrderInfo .Right li span{
		color: rgba(228,0,0,1);
		margin: 0px 10px;
	}
	
	.Form{
		margin-top: 30px;
	}
	.Item{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	.Item .Left{
		width: 160px;
		text-align: right;
		padding-right: 20px;
	}
	.Item .Right{
		flex: 1;
		display: flex;
	}
	.Item .Right .ServiceType{
		margin-right: 15px;
		padding: 10px 20px;	
		border: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
	}
	.Item .Right .On{
		border: 1px solid rgba(228,0,0,1);
		color: rgba(228,0,0,1);
	}
	.Item .Right li{
		display: flex;
		align-items: center;
		width: 100%;
	}
	.Item .Right li span{
		margin-right: 10px;
		color: #999999;
	}
	
	.PreviewImg{
		display: inline-block;
		width: 100px;
		height: 100px;
		border:1px dotted rgba(0,0,0,0.1);
		position: relative;
		margin-right: 5px;
	}
	.PreviewImg:hover{
		border:1px dotted rgba(228,0,0,0.7);
	}
	.PreviewImg i.Remove{
		display: none;
	}
	.PreviewImg:hover i.Remove{
		display: inline-block;
		position: absolute;
		bottom: 0px;
		right: 0px;
		background-color: rgba(228,0,0,0.8);
		padding: 2px 5px;
		border-radius: 2px;
		color: #FFFFFF;
		cursor: pointer;
		font-style: normal;
	}
	.PreviewImg .ShowContent{
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
	}
	.PreviewImg .ShowContent img{
		width: 90%;
		height: 90%;
	}
	.Sp .el-input__inner{
	    width: 400px;
	    height: 500px;
	 }
</style>
